<template>
    <!-- 文章修改页 -->
    <div class="pdf">
        <!-- 标题 -->
        <div>
            <p class="p-padd">标题</p>
            <el-input
                v-model="title"
                placeholder="请输入内容"
                class="input-width"
            ></el-input>
        </div>
        <br />
        <!-- 分类select -->
        <div>
            <span>封面图：</span> 
            <br>
            <br>
            <el-upload
                class="avatar-uploader el-upload-padding"
                :action="uploadUrl"
                :show-file-list="false"
                :data = {id:article_id}
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
                <img v-if="logo" :src="logo" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
        </div>
        <br />
        <!-- 正文 -->
        <div class="editor-container">
            <!-- ===================  editor组件 start  ==================== -->
            <p class="p-padd"><b>正&nbsp;&nbsp;&nbsp;&nbsp;文</b></p>
            <!-- <vue-ueditor-wrap :config="myConfig" v-model="defaultMsg" v-if="ueState"></vue-ueditor-wrap> -->
            <vue-ueditor-wrap
                v-if="ueState"
                v-model="defaultMsg"
                :config="myConfig"
                @ready="ready"
            ></vue-ueditor-wrap>
            <!-- ===================  editor组件 end    ==================== -->
        </div>
        <br />
        <!-- 发布时间 -->
        <div class="block">
            <p class="p-padd"><b>发布日期</b></p>
            <el-date-picker
                v-model="datePicker"
                type="datetime"
                placeholder="选择日期时间">
            </el-date-picker>
        </div>
        <br />
        <div style="padding-top:30px">
            <el-button @click="goBack()">返&nbsp;&nbsp;回</el-button>
            <el-button type="primary" @click="execUpdateArticle()"
                >保&nbsp;&nbsp;存</el-button
            >
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import "@/assets/css/teacher/center/webNewsUpdate.scss";
</style>

<script>
import webNewsUpdate from "@/assets/js/teacher/center/webNewsUpdate.js";
export default {
    ...webNewsUpdate
};
</script>
