import { request } from "@/request/http";

export default {
  updateNews: (data) => {
    return request("POST", `/index.php/center/updateNews`, data);
  },

  getNewsInfo: (data) => {
    return request("GET", `/index.php/center/getNewsInfo`, data);
  },
}
